import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { FaPhoneAlt } from "react-icons/fa";
import { Link } from "react-router-dom";

function Heder() {
  return (
    // --------Heder start-------

    <div>
      <Navbar expand="lg" className="bg-body-tertiary pt-3 pb-3 ">
        <Container>
          <Navbar.Brand href="#home" className="logo">
            <Link to='/'>
              <img src={require("../images/1.png")}></img>
            </Link>
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />

          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="m-auto">
              <Nav.Link href="#home">
                <Link to="/">Home</Link>{" "}
              </Nav.Link>
              <Nav.Link href="#link">
                <Link to="/About">About</Link>
              </Nav.Link>
              <Nav.Link href="#link">
                <Link to="/Contact">Contact</Link>
              </Nav.Link>
              <Nav.Link href="#link">
                <Link to="/Services">Services</Link>
              </Nav.Link>
              {/* <Nav.Link href="#link">
                <Link to="/career">Career</Link>
              </Nav.Link> */}
            </Nav>
          </Navbar.Collapse>
          <div className="buutton ">
            <a
              href="tel:+919825131393"
              className="m-0 p-0 text-decoration-none"
            >
              <FaPhoneAlt className="me-2" />
              +919825131393
            </a>
          </div>
        </Container>
      </Navbar>
    </div>

    // --------Heder and--------
  );
}

export default Heder;
