import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FaStar } from "react-icons/fa";

function   Sec_H_1() {
  return (
    <>
      <div className="Sec_H_1">
        <Container>
          <Row className="justify-content-around">
            <Col xl={6} lg={6} md={12} sm={12}>
              <div className="slider_top">
                <p>Software Engineering</p>
                <h3>
                  A harmony of software house skills and digital agency
                  sensibility
                </h3>
              </div>
            </Col>

            <Col xl={4} lg={6} md={12} sm={12} className="pp1">
              <div className="slider_bottom">
                <img src={require("../images/3.jpg")} alt="Slide" />
                <div className="slider_contain">
                  <div className="box_S_1 d-flex justify-content-around align-items-center">
                    <div>
                      <h5 className="fs-5 mb-2">Total Projects</h5>
                      <p className="p-0 m-0">150 + Completed</p>
                    </div>
                    <div className="s_img">
                      <img src={require("../images/3.jpg")} alt="Small" />
                    </div>
                  </div>
                </div>
                <div className="slider_contain_2">
                  <div className="box_S_2">
                    <div>
                      <h5 className="p-0 m-0 fs-5">Happy Customer</h5>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <p className="p-0 m-0">1200 +</p>
                      <ul className="d-flex list-unstyled star-icon align-items-center m-0">
                        <li>
                          <FaStar />
                        </li>
                        <li>
                          <FaStar />
                        </li>
                        <li>
                          <FaStar />
                        </li>
                        <li>
                          <FaStar />
                        </li>
                        <li>
                          <FaStar />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="slider_contain_3">
                  <div className="box_S_3 d-flex justify-content-around">
                    <div>
                      <h5 className="p-0 m-0 fs-6">Sale Report</h5>
                      <p className="p-0 m-0">Real sales analysis</p>
                    </div>
                    <div className="rec_box">
                      <h1 className="m-0"></h1>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Sec_H_1;
