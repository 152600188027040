import React from 'react'
import Sec_S_1 from '../../services_Components/Sec_S_1';

function Services() {
  return (
    <div>
       <Sec_S_1/>
    </div>
  )
}

export default Services
