import logo from "./logo.svg";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Routes, Route } from "react-router-dom";
import Heder from "./Components/Heder";
import Footer from "./Components/Footer";

import Home from "./pages/Home/Home";

import About from "./pages/About/About";

import Contact from "./pages/Contact/Contact";

import Services from "./pages/Services/Services";
import ScrollToTop from "./Scroll_up/ScrollToTop";
import Sec_C2_1 from "./Career_Components/Sec_C2_1";
import Career from "./pages/Career/Career";


function App() {
  return (
    <div>
      <Heder />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/About" element={<About />} />
        <Route path="/About" element={<About />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/Services" element={<Services />} />
        {/* <Route path="/Career" element={<Career/>} /> */}
      </Routes>
      
     
      <Footer />
    </div>
  );
}

export default App;
