import React, { useState, useEffect } from "react";
import { Col, Container, Nav, Row } from "react-bootstrap";
import AOS from "aos";
import "aos/dist/aos.css";
// import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// Content data for each category
const allContent = [
  {
    category: "React",
    img: require("../images/i-1.jpg"), // Replace with the actual image path
    title: "Thinking in React",
    description:
      "React is, in our opinion, the premier way to build big, fast Web apps with JavaScript. It has scaled very well for us at Facebook and Instagram.",
    additionalText:
      "The first thing you’ll want to do is to draw boxes around every component. If you’re working with a designer, they may have already done this.",
    icon: require("../images/16.png"),
  },
  {
    category: "Delphi",
    img: require("../images/i-2.jpg"),
    title: "Introduction to Delphi",
    description:
      "Delphi is an IDE for console, desktop, web, and mobile applications. It supports rapid application development.",
    additionalText:
      "Delphi is known for its ease of use and powerful frameworks.",
    icon: require("../images/19.png"),
  },
  {
    category: "MS SQL",
    img: require("../images/i-3.jpg"),
    title: "MS SQL Database",
    description:
      "MS SQL is a robust database management system that supports a wide variety of applications.",
    additionalText:
      "MS SQL offers enterprise-level management and scalability.",
    icon: require("../images/17.png"),
  },
  {
    category: "Mongo DB",
    img: require("../images/i-4.jpg"),
    title: "Working with MongoDB",
    description:
      "MongoDB is a NoSQL database that uses JSON-like documents with schema.",
    additionalText:
      "It’s popular for applications requiring large volumes of unstructured data.",
    icon: require("../images/18.png"),
  },
  {
    category: "React Native",
    img: require("../images/i-5.jpg"),
    title: "React Native for Mobile",
    description:
      "React Native enables you to build mobile apps using only JavaScript and React.",
    additionalText: "Write once, deploy on both iOS and Android.",
    icon: require("../images/16.png"),
  },
];

const Sec_H_5 = () => {
  const [activeCategory, setActiveCategory] = useState("React");
  const [filteredContent, setFilteredContent] = useState(null);
  const [data, setdata] = useState(allContent);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
    setFilteredContent(
      allContent.find((item) => item.category === activeCategory)
    );
  }, [activeCategory]);

  const selectCategory = (category) => {
    setActiveCategory(category);
  };
  var settings = {
    // dots: true,
    auto: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="hed">
        <h1 className="About_titel">Services</h1>
      </div>
      <div className="Main_Cart">
        <Container>
          <div className="justify-content-between flex-wrap d-flex pb-4 tt">
            <h4 className="ps-3 fs-3">Our featured projects</h4>
            <ul className="nav pe-3 fs-6">
              <li className="p-3">APP</li>
              <li className="p-3">DEVELOPMENT</li>
              <li className="p-3">UI DESIGN</li>
            </ul>
          </div>
          <Row className="cart">
            <Col lg={4} md={6} className="mm pt-sm-4  pt-md-0  ">
              <img src={require("../images/11.jpg")}></img>
            </Col>
            <Col lg={4} md={6} className="mm pt-sm-4  pt-md-0 ">
              <img src={require("../images/12.jpg")}></img>
            </Col>
            <Col lg={4} md={6} className="mm pt-sm-4 pt-md-4 pt-lg-0 ">
              <img src={require("../images/13.jpg")}></img>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="main_Sec_H_5">
        <Container>
          <Row className="filter-2">
            <Col lg={12}>
              <h2 className="text-center hed_h">
                Let's build long-lasting software together
              </h2>
            </Col>
            <Col lg={12} className="pt-5">
              <Slider {...settings}>
                {data.map((item, index) => (
                  <div
                    key={index}
                    onClick={() => selectCategory(item.category)}
                    className="d-flex justify-content-center "
                  >
                    <div
                      className={`category-item ${
                        activeCategory === item.category ? "active" : ""
                      } text-center`}
                    >
                      <img
                        className="icon"
                        style={{ margin: "10px auto" }}
                        src={item.icon}
                        alt={item.category}
                      />
                      <p className="fs-5">{item.category}</p>
                    </div>
                  </div>
                ))}
              </Slider>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="gg">
        <Container>
          <Row className="text-ui ">
            <Col lg={6} className="">
              {filteredContent ? (
                <div className="image">
                  <img
                    src={filteredContent.img}
                    alt={filteredContent.category}
                  />
                </div>
              ) : (
                <p>No content to display. Please select a category.</p>
              )}
            </Col>
            <Col lg={6}>
              <div className="text ps-sm-0 pe-sm-0">
                <div className="box-top  my-4 rounded">
                  <h2>
                    {filteredContent
                      ? filteredContent.title
                      : "Select a Category"}
                  </h2>
                  <p>
                    {filteredContent
                      ? filteredContent.description
                      : "Please select a category to view details."}
                  </p>
                </div>
                <div className="box-top  my-4 rounded">
                  <p>{filteredContent ? filteredContent.additionalText : ""}</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Sec_H_5;
